import React from "react";
import Layout from "../components/layout"
import Head from "../components/head"
import {GrGooglePlay} from "react-icons/gr"
import CustomHeading from "../components/customHeading";
import workStyles from "../styles/work.module.css";

// import Header from "../components/header";
import BackButton from "../components/backButton";
import {Link} from 'gatsby'
import Footer from "../components/footer";

export default function About() { 
    return <div>
        <Head title="Work" />
        <CustomHeading title="Work" />
        <BackButton/>
        {/* <Layout> */}
        <div style={{textAlign:"center"}}>

           
           
            <ol style={{ textAlign: "left", display: "inline-block"}}>
                    <li>
    
                <p className={workStyles.work}>Lyrik - Instant Lyrics Search </p>
                <p className={workStyles.subHeader}>
                        <Link
                            
                            href="https://play.google.com/store/apps/details?id=com.buildjuice.lyriks&hl=en"
                            style={{ textDecoration: "none" ,color:"rgb(34,31,32)",opacity:"0.8"}}
                        >Check out on <GrGooglePlay color="rgb(34,31,32)" size="1em" /></Link>   
                </p>   
                    </li>
                </ol>
            </div>
            <Footer/>
        </div>
            
}